/* General styling for the app */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f2f2f2; /* Matches your primary color */
  color: #484848; /* Light text color for better contrast */
}

.App-header {
  background-color: #484848; /* Matches the menu color */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #f2c230; /* Accent color for links */
  text-decoration: none;
}

.App-link:focus {
  outline: 2px solid #f2c230; /* Yellow border */
  outline-offset: 2px;
}

.App-link:hover {
  color: #bf9d36; /* Darker yellow for hover effect */
}

/* Sidebar styling */
.MuiDrawer-root {
  position: relative;
  z-index: 1000;
}

.MuiDrawer-paper {
  background-color: #484848;
  color: white;
  border-right: 1px solid #333;
  transition: width 0.3s ease-in-out;
}

.MuiListItem-button {
  padding: 10px 20px;
}

.MuiListItemIcon-root {
  color: white;
}

/* Collapse button inside the sidebar */
.collapse-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
}

.collapse-btn:hover {
  color: #f2c230;
}

/* Main content */
.main-content {
  margin-left: 240px; /* Default sidebar width */
  transition: margin-left 0.3s ease-in-out;
  padding: 20px;
  background-color: #f2f2f2;
  min-height: 100vh;
}

.main-content.collapsed {
  margin-left: 80px; /* Collapsed sidebar width */
}

/* Footer styling */
footer {
  text-align: center;
  padding: 10px;
  background-color: #484848;
  color: #f2f2f2;
  position: relative;
}

/* Add active link styling for the sidebar */
.active-link {
  background-color: #f2c230;
  color: #333 !important;
}

.active-link .MuiListItemIcon-root {
  color: #333;
}

/* Global link styling reset */
a {
  text-decoration: none;
  color: inherit;
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .MuiDrawer-paper {
    width: 80px; /* Shrink sidebar width for mobile */
  }

  .MuiListItem-button {
    padding: 8px 10px; /* Smaller padding for mobile */
  }

  .MuiListItemText-root {
    display: none; /* Hide text in the sidebar for mobile */
  }

  /* Main content adjustments */
  .main-content {
    margin-left: 80px; /* Adjust margin for smaller sidebar */
    padding: 15px; /* Reduce padding for mobile */
  }

  /* Header adjustments */
  .App-header {
    font-size: calc(10px + 1.5vmin); /* Smaller font size for header */
    padding: 10px; /* Reduce padding for smaller screens */
    min-height: auto; /* Allow the height to adjust dynamically */
    height: auto; /* Ensure height adjusts to content */
    justify-content: flex-start; /* Align content to the top */
  }

  /* Footer adjustments */
  footer {
    font-size: 0.8rem; /* Reduce font size for mobile footer */
  }
}

@media (max-width: 480px) {
  /* Further adjustments for very small screens */
  .MuiDrawer-paper {
    width: 70px; /* Further shrink sidebar for very small screens */
  }

  .main-content {
    margin-left: 70px; /* Adjust margin for even smaller sidebar */
    padding: 10px; /* Further reduce padding for very small screens */
  }

  /* Adjust text sizes */
  .App-header {
    font-size: calc(10px + 1vmin); /* Further reduce header font size */
    padding: 10px;
    justify-content: flex-start;
  }

  footer {
    font-size: 0.7rem; /* Smaller footer font size */
  }
}